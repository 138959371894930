<template>
  <div class="viewgaodu">
    <!--地图-->
    <div id="container"></div>

    <!--左侧-->
    <div class="leftmap">
      <div class="leftmap-div">
        黄冈市黄州区顺茂汽车救援服务部
      </div>
      <div class="leftmap-div1">
        <el-input
          placeholder="输入名称"
          v-model="input"
          clearable>
        </el-input>
      </div>
      <div class="leftmap-div2">
        <!-- <el-time-picker
          class="shijianduan"
          is-range
          v-model="value1"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围">
        </el-time-picker> -->
      </div>
      <div class="leftmap-div3">
        <el-button size="small" type="danger" @click="del()">一键清除</el-button>
        <el-button size="small" type="primary" @click="onekey()">一键布点</el-button>
      </div>
      <div class="leftmap-div4">
        在线技师数量：14
      </div>
      <div class="leftmap-div5">
        虚拟技师数量：0
      </div>
      <div class="leftmap-div6">
        <div v-for="(item,index) in List" :key="index" class="leftmap-div7">
          <span class="driverName">
            {{item.name}}
            <span class="virtualTotal">{{item.value}}</span>
          </span>
          <img src="@/assets/images/home/siji.png" alt="" class="serverIcon">
        </div>
      </div>
      <div class="leftmap-div10">
        <el-button class="leftmap-div10-left" @click="returnPage">取消</el-button>
        <el-button type="primary">确认</el-button>
      </div>
    </div>

    <!--顶部搜索条件-->
    <div class="wheretop">
      <div class="wheretop1">
        <el-cascader
          v-model="value"
          :options="city"
          :props="{ checkStrictly: true }"
          @change="handleChange"
          clearable></el-cascader>
      </div>
      <div class="wheretop2">
        <div class="xuanzedidianyangshi" @click="opensiteselection()">
          <span v-if="address == undefined || address == ''">选择位置</span>
          <el-tooltip class="item" effect="dark" :content="address" placement="top-start">
            <div class="didian">{{address}}</div>
          </el-tooltip>
          <i class="el-icon-location-information"></i>
        </div>
      </div>
      <div class="wheretop3">
        <el-input
          placeholder="请输入司机名称"
          v-model="name"
          clearable>
        </el-input>
      </div>
      <div class="wheretop4">
        <el-date-picker
          v-model="value2"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div>
        <el-checkbox v-model="checked">
          <span style="color: #fff">聚合订单</span>
        </el-checkbox>
        <el-checkbox v-model="checked1">
          <span style="color: #fff">热力地图</span>
        </el-checkbox>
      </div>
    </div>

    <!--批量布点规则-->
    <el-dialog
      center
      title="批量布点规则"
      :close-on-click-modal="false"
      :visible.sync="showbatch"
      width="25%"
      :before-close="handbatch">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px">
        <el-form-item label="半径">
          <el-input placeholder="输入半径" v-model="form.banjing" clearable>
            <template slot="append">km</template>
          </el-input>
        </el-form-item>
        <el-form-item label="最小数量">
          <el-input placeholder="输入最小数量" v-model="form.zuixiao" clearable>
            <template slot="append">km</template>
          </el-input>
        </el-form-item>
        <el-form-item label="最大数量">
          <el-input placeholder="输入最大数量" v-model="form.zuida" clearable>
            <template slot="append">km</template>
          </el-input>
        </el-form-item>
        <el-form-item label="关键字">
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="汽车维修"></el-checkbox>
            <el-checkbox label="汽车服务"></el-checkbox>
            <el-checkbox label="汽车销售"></el-checkbox>
            <el-checkbox label="餐饮服务"></el-checkbox>
            <el-checkbox label="购物服务"></el-checkbox>
            <el-checkbox label="医疗保障服务"></el-checkbox>
            <el-checkbox label="商务住宅"></el-checkbox>
            <el-checkbox label="科教文化服务"></el-checkbox>
            <el-checkbox label="生活服务"></el-checkbox>
            <el-checkbox label="住院服务"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button
          @click="updateVisible()">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          @click="save">提交
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      center
      v-if="dialogVisible == true"
      title="选择地址"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose">
      <div>
        <!--自定义高德选点组件-->
        <AMapLoadersiteselection v-if="showAMapLoadersiteselection == true" :data="origin" @location="getlocation"/>
      </div>
    </el-dialog>


  </div>
</template>

<script>
//引入高德地图
import AMapLoader from '@amap/amap-jsapi-loader';
//引入高德地图key
import setting from '@/config/config'
// 引入的接口
import {getcityList} from "@/api/yunli";
//引入自定义高德地图选点组件
import AMapLoadersiteselection from '@/components/AMapLoadersiteselection/index.vue'


export default {
  components: {AMapLoadersiteselection},
  props: {
    // 修改回显的数据
    data: Object
  },
  data(){
    return{
      //此处不声明 map 对象，可以直接使用 this.map赋值或者采用非响应式的普通对象来存储。
      map:null,

      input:'',
      value1:'',

      List:[
        {
          name:'顾明国',
          value:'0'
        },
        {
          name:'石龙飞',
          value:'0'
        },
        {
          name:'周浩',
          value:'0'
        },
        {
          name:'尹宇韦',
          value:'0'
        },
        {
          name:'李冰',
          value:'0'
        },
        {
          name:'陶陪',
          value:'0'
        },
        {
          name:'曾志康',
          value:'0'
        },
        {
          name:'程扬志',
          value:'0'
        },
        {
          name:'王伟',
          value:'0'
        },
        {
          name:'李友元',
          value:'0'
        },
        {
          name:'胡应',
          value:'0'
        },
        {
          name:'郑亮',
          value:'0'
        },
      ],

      //批量布点
      showbatch:false,

      // 批量添加规则的表单
      form:{},
      // 表单验证规则
      rules: {
        area: [
          { required: true, message: '请选择区域', trigger: 'change' },
        ],
        driver_count: [
          { required: true, message: '请输入司机数量', trigger: 'change' },
        ],
        frequency:[
          { required: true, message: '请输入频率', trigger: 'blur' },
        ],
        times:[
          { required: true, message: '请选择时间段', trigger: 'change' },
        ]
      },

      //关键词
      checkList:[],

      //城市列表
      city:[],
      value:'',

      //选择地址
      address:'',
      //选择地址的坐标
      address_coord:[],
      //选择地址
      dialogVisible:false,
      //iframe
      keyUrl:'',
      showAMapLoadersiteselection:false,

      //司机名字
      name:'',
      //时间段
      value2:'',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },

      // 聚合订单复选框
      checked:true,
      // 热力地图复选框
      checked1:true,

    }
  },

  computed: {
    // 当前登录用户信息
    loginUser () {
      return this.$store.state.admin.userInfo
    },
  },

  mounted() {
    // 高德地图
    window._AMapSecurityConfig = {
      securityJsCode: setting.secretkey,
    }

    //获取城市
    this.getCity();

    // 获取地图
    this.initMap();
  },

  methods: {
    // 初始化高德地图
    initMap(){
      AMapLoader.load({
        key: setting.key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Driving','AMap.InfoWindow','AMap.MarkerCluster'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map("container", {
          // 设置地图容器id
          mapStyle: "amap://styles/grey",
          zoom: 13,
          center: [116.397428, 39.90923],//地图中心点
          resizeEnable: true
        });
        const map = this.map;
        var gridSize = 60
        // // 数据中需包含经纬度信息字段 lnglat
        var points = [
          { lnglat: ["108.939621", "34.343147"] },
          { lnglat: ["108.939621", "34.343147"] },
          { lnglat: ["108.939621", "34.343147"] },
          { lnglat: ["108.939621", "34.343147"] },
          { lnglat: ["108.939621", "34.343147"] },
          { lnglat: ["108.939621", "34.343147"] },
          { lnglat: ["108.939621", "34.343147"] },
          { lnglat: ["108.939621", "34.343147"] },
          { lnglat: ["108.939621", "34.343147"] },
          { lnglat: ["112.985037", "23.15046"] },
        ]
        var cluster = new AMap.MarkerClusterer(this.map, points, {gridSize: gridSize});

        // 自定义点击事件
        cluster.on('click', function(e) {
          console.log(e)
          var curZoom = map.getZoom();
          console.log(curZoom)
          if(curZoom < 20){
            curZoom += 2;
          }
          map.setZoomAndCenter(curZoom, e.lnglat);
        });

      }).catch((e) => {
        console.log(e);
      });
    },

    //点击一键清除
    del(){
      let that = this;
      this.$utils.confirm("确认清除所有技师虚拟位置吗？", function () {
        // 执行一些需要的逻辑
      }, "确定", 'warning');
    },

    //关闭批量布点
    handbatch(){
      this.showbatch = false;
    },
    //批量取消
    updateVisible(){
      this.showbatch = false;
    },
    // 批量 确认操作
    save(){
      this.showbatch = false;
    },

    // 点击一键布点
    onekey(){
      this.showbatch = true;
    },

    //选择顶部地区
    handleChange(value){
      console.log(value)
    },

    // 获取城市列表
    getCity(){
      getcityList().then(res => {
        this.city = res.data;
      })
    },

    //点击选择地址
    opensiteselection(){
      this.dialogVisible = true;
      this.showAMapLoadersiteselection = false;
      this.$nextTick(() => {
        this.showAMapLoadersiteselection = true;
      })
    },
    //获取选择地址后的数据
    getlocation(value){
      console.log(value)
      //选择地址的数据
      this.address = value.pname + value.cityname + value.adname + value.address;
      this.address_coord = [];
      // 调用重新渲染组件的方法
      this.reloadComponent();
      this.dialogVisible = false;
    },
    // 重新渲染组件
    reloadComponent() {
      this.currentComponent = null // 先把当前组件置为 null
      this.$nextTick(() => {
        this.currentComponent = AMapLoader // 然后再把要加载的组件赋值给 currentComponent
      })
    },
    //关闭
    handleClose(){
      this.dialogVisible = false;
    },


    //返回按钮
    returnPage(){
      this.$emit('update:visible', false);
      this.$emit('done');
    },

  }

}
</script>

<style lang="scss" scoped>
.viewgaodu{
  position: relative;
  height: calc(93.2vh); /* 假设底部留白是100px，根据实际情况调整 */
  box-sizing: border-box; /* 确保内边距和边框不会影响总高度 */
  padding-bottom: 0; /* 确保没有底部内边距 */
  margin-bottom: 0; /* 确保没有底部外边距 */
}
#container{
  padding:0px;
  margin: 0px;
  width: 100%;
  height: 100%;
}

.leftmap{
  width: 340px;
  height: calc(93.2vh); /* 假设底部留白是100px，根据实际情况调整 */
  padding: 20px;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  .shijianduan{
    width: 270px !important;
  }
  .leftmap-div{
    text-align: center;
    margin-bottom: 10px;
    font-size: 15px;
  }
  .leftmap-div1{
    margin-bottom: 10px;
  }
  .leftmap-div2{
    margin-bottom: 10px;
  }
  .leftmap-div3{
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
  .leftmap-div4{
    font-size: 14px;
    margin-top: 16px;
  }
  .leftmap-div5{
    font-size: 14px;
    margin-top: 16px;
  }
  .leftmap-div6{
    padding: 20px 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-height: calc(100vh - 302px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    overflow-y: scroll;
  }
  .leftmap-div7{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 16px;
    cursor: pointer;
    width: 95px;
  }
  .driverName{
    width: 80px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 6px;
    font-size: 14px;
  }
  .virtualTotal{
    color: #1890ff;
  }
  .serverIcon{
    width: 55px;
    height: 55px;
  }
  .leftmap-div10{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    left: 80px;
  }
  .leftmap-div10-left{
    margin-right: 15px;
  }
}

.wheretop{
  position: absolute;
  top: 15px;
  left: 360px;
  display: flex;
  align-items: center;
  .wheretop1{
    margin-right: 15px;
  }
  .wheretop2{
    margin-right: 15px;
  }
  .wheretop3{
    margin-right: 15px;
  }
  .wheretop4{
    margin-right: 15px;
  }
}

//选择地点样式
.xuanzedidianyangshi{
  background: #FFFFFF;
  border-radius: 5px;
  -webkit-appearance: none;
  border: 1px solid var(--border-color-base);
  box-sizing: border-box;
  color: var(--color-text-regular);
  font-size: inherit;
  height: 36px;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .didian{
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
  }
}
</style>
